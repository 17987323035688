var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Slider = function (parent,jQuery) {
    "use strict";

    jQuery(document).ready(function ($) {
        $('.flexslider').flexslider({
            animation: "slide"
        });
    });

}(EvangelischeOmroep.Slider || {}, jQuery);