var EvangelischeOmroep = EvangelischeOmroep || {}
EvangelischeOmroep.Bijbel = EvangelischeOmroep.Bijbel || {}

var Scroll = React.createClass({
  getInitialState: function () {
    var state = this.addVerses(this.props.verses)
    state.currentChapter = this.props.chapter
    this.saveVerseCache(this.props.chapter, this.props.verses)
    state.nbgWall = false
    return state
  },
  addVerses: function (verses) {
    var data = {}
    var chapters = []
    var bookId = ''
    var bookTitle = ''
    if (this.state) {
      data = this.state.data
      chapters = this.state.chapters
      bookId = this.state.bookId
      bookTitle = this.state.bookTitle
    }
    if (verses.length && verses[0]._source) {
      bookId = verses[0]._source.book_id
      bookTitle = verses[0]._source.book_title
    }
    if (EvangelischeOmroep.Bijbel.newVersesListener) {
      EvangelischeOmroep.Bijbel.newVersesListener(verses)
    }

    verses.forEach(function (verse) {
      if (!chapters.includes(verse._source.chapter)) {
        chapters.push(verse._source.chapter)
      }
      verse._source.block_ids.forEach(function (block_id) {
        if (!data.hasOwnProperty(block_id)) {
          data[block_id] = [verse]
        } else {
          data[block_id].push(verse)
          data[block_id].sort(function (verseA, verseB) {
            return verseA._source.sort_id - verseB._source.sort_id
          })
        }
      })
    })
    chapters.sort()
    return {data: data, chapters: chapters, bookId: bookId, bookTitle: bookTitle}
  },
  pathToChapter: function (newChapter) {
    var path = window.location.pathname.split('/')
    path[3] = newChapter
    return path.join('/')
  },
  updateUrlAndTitle: function (chapter) {
    var title = this.state.bookTitle + ' ' + chapter + ' - Bijbel'
    history.replaceState({}, title, this.pathToChapter(chapter))
    document.title = title

    // Using deprecated `initCustomEvent` method to support IE10
    var event = document.createEvent('CustomEvent')
    event.initCustomEvent('bijbel-nav-action', true, true, null)
    document.dispatchEvent(event)
  },
  componentDidMount: function () {
    //this.addVerses(this.props.verses);
  },
  loadChapterSucces: function (state, callback) {
    this.setState(state)
    callback()
    if (EvangelischeOmroep.Bijbel.Util) {
      EvangelischeOmroep.Bijbel.Util.linkXrefsAndFootnotes()
    }
    if (EvangelischeOmroep.Bijbel.updateChapterListener) {
      EvangelischeOmroep.Bijbel.updateChapterListener(state.currentChapter)
    }
    if (document.verseSelectorInstance) {
      document.verseSelectorInstance.forceUpdate()
    }
    if (EvangelischeOmroep.Bijbel.Fancybox) {
      EvangelischeOmroep.Bijbel.Fancybox.bind(jQuery)
    }
    window.scrollTo(0, 0)
    this.updateUrlAndTitle(state.currentChapter)

    window.validateNbgWall()
  },
  saveVerseCache (chapter, verses) {
    var verseCache = window.verseCache || {}
    if (verseCache.hasOwnProperty(chapter)) {
      return
    }

    verseCache[chapter] = verses
    window.verseCache = verseCache
  },
  loadChapter: function (chapter, callback) {
    if (this.state.chapters.includes(chapter)) {
      window.verses = window.verseCache[chapter]
      this.loadChapterSucces({currentChapter: chapter}, callback)
      return
    }

    if (jQuery) {
      jQuery.ajax({
        url: '/api/' + this.state.bookId + '/' + chapter,
        dataType: 'json',
        success: function (data) {
          var state = this.addVerses(data)
          state.currentChapter = chapter
          this.saveVerseCache(chapter, data)
          window.verses = window.verseCache[chapter]
          this.loadChapterSucces(state, callback)
        }.bind(this),
        error: function (xhr, status, err) {
          if (console) {
            console.error(this.props.url, status, err.toString())
          }
          callback()
        }.bind(this),
      })
    } else {
      window.location.path = this.pathToChapter(currentChapter)
    }
  },
  addNbgWall: function () {
    this.setState({nbgWall: true})
  },
  removeNbgWall: function () {
    this.setState({nbgWall: false})
  },
  getReadOnPosition: function (blockIds, position) {
    // Return 3 verses earlier than NBGwall is placed
    let positionVerse = this.state.data[blockIds[position]][0]._source.number - 3;
    return positionVerse >= 1 ? positionVerse : 1
  },
  render: function () {
    var blockNodes = []
    var blockIds = []
    var previousChapter = this.state.currentChapter - 1
    var nextChapter = this.state.currentChapter + 1
    var that = this

    // Only care about max chapters if the header is rendered.
    var maxChapters = this.props.chapterCount
    if (typeof document !== 'undefined' && document.querySelector('.selectBook option[selected]')) {
      maxChapters = document.querySelector('.selectBook option[selected]').getAttribute('data-chapters')
    }

    for (var property in this.state.data) {
      if (this.state.data.hasOwnProperty(property) && this.state.data[property].length) {
        blockIds.push(property)
      }
    }
    blockIds.sort()

    blockIds.forEach(function (blockId) {
      var blockData = that.state.data[blockId]
      blockData = blockData.filter(function (verse) {
        return verse._source.chapter === that.state.currentChapter
      })
      if (blockData.length) {
        blockNodes.push(
          <TextBlock key={blockId} blockId={blockId}>{blockData}</TextBlock>,
        )
      }
    })

    // Insert NBG wall
    if (that.state.nbgWall) {

      if (blockNodes.length <= 2) {
        // Short chapter
        if (that.state.data[blockIds[0].length] > 3) {
          // More than 3 verses: insert at the end
          let currentVerse = this.getReadOnPosition(blockIds, blockNodes.length)
          blockNodes.push(
            <NbgWall hideWall={that.removeNbgWall} book={this.state.bookTitle} chapter={this.state.currentChapter} verse={currentVerse} />
          )
        } else {
          that.state.nbgWall = false
        }
      } else {
        // Long chapter, insert at 1/3 of the chapter
        let insertPlace = parseInt(blockNodes.length / 3)
        let currentVerse = this.getReadOnPosition(blockIds, insertPlace)
        blockNodes.splice(insertPlace, 0,
          <NbgWall hideWall={that.removeNbgWall} book={this.state.bookTitle} chapter={this.state.currentChapter} verse={currentVerse} />
        )
      }
    }

    var title
    if (this.state.currentChapter !== 1) {
      title = (
        <h1 className='chapterTitle'>{this.state.bookTitle} {this.state.currentChapter} <abbr
        title='Uit: De Nieuwe Bijbelvertaling &copy; 2004/2007 Nederlands Bijbelgenootschap' className='copyrightIcon'>&copy;</abbr></h1>
      )
    }


    return (
      <div className='scroller'>
        {maxChapters !== null &&
          <div className='chapterNavigation'>
            <LoadingButton onStartLoading={this.loadChapter}
                           chapter={previousChapter}
                           extraClass='buttonChapter buttonChapter--previous js-load-chapter'
                           max={maxChapters}/>
            <LoadingButton onStartLoading={this.loadChapter}
                           chapter={nextChapter}
                           extraClass='buttonChapter buttonChapter--next js-load-chapter'
                           max={maxChapters}/>
          </div>
        }

        {maxChapters !== null && title}

        <div className='bible'>
          {blockNodes}
        </div>

        {maxChapters !== null &&
          this.state.nbgWall === false &&
          <LoadingButton onStartLoading={this.loadChapter}
                         chapter={nextChapter}
                         extraClass='moreLinkCenter moreLinkCenter--fullwidth js-load-chapter'
                         max={maxChapters}>Volgend hoofdstuk</LoadingButton>
        }

      </div>
    )
  },
})
