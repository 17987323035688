/**
 * Sets up the Settings (Instellingen) page.
 */
(function(document, window) {

	'use strict';

	if (!('serviceWorker' in navigator) || (!'fetch' in window)) {
		return;
	}

	window.OneSignal = window.OneSignal || [];

	/**
	 * Handles the checkbox change event.
	 *
	 * @param {Event} e
	 */
	function handleSubscriptionChange(e) {
		window.OneSignal.push(function() {
			if (e.target.checked) {
				// Show the browser's native notification permission prompt
				// If permission is already granted, nothing will happen
				window.OneSignal.registerForPushNotifications();
			}

			// This will mute/unmute the user's subscription if the user has
			// already subscribed
			window.OneSignal.setSubscription(e.target.checked);
		});
	}

	/**
	 * Sets up the Toggle Button for subscribing/unsubscribing to push notifications.
	 *
	 * @param {Element} container
	 */
	function setupNotificationsToggle(container) {
		var checkbox = container.querySelector('input[name="notifications-enable"]');

		window.OneSignal.push(function() {
			window.OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
				checkbox.checked = isEnabled;

				checkbox.addEventListener('change', handleSubscriptionChange);

				container.removeAttribute('hidden');
			});

			window.OneSignal.on('popoverShown', function() {
				checkbox.removeEventListener('change', handleSubscriptionChange);

				window.OneSignal.once('subscriptionChange', function(subscribed) {
					checkbox.checked = subscribed;

					checkbox.addEventListener('change', handleSubscriptionChange);
				});
			});
		});
	}

  /**
   * Adds the Settings item to the menu
   */
  function addSettingsMenuItem () {
    var footerLastItem = document.querySelector('.footerMenu .nav-link:last-of-type')

    if (!footerLastItem) {
      return;
    }

    footerLastItem.insertAdjacentHTML('afterend', '<a class="nav-link" href="/instellingen">Meldingen instellen</a>')
  }

	document.addEventListener('DOMContentLoaded', function() {
		addSettingsMenuItem();

		var container = document.querySelector('.js-page-settings-notifications-enable');

		if (!container) {
			return;
		}

		setupNotificationsToggle(container);
	});

})(document, window);
