var Addon = React.createClass({
    render: function() {
        var title = this.props.addon.title;
        var text = this.props.addon.content;
        var category = this.props.addon.category;
        var startChapter = this.props.addon.start_chapter
        var startVerse = this.props.addon.start_verse
        var endChapter = this.props.addon.end_chapter
        var endVerse = this.props.addon.end_verse
        var iconName = '';
        if(category == 'Beloofd = beloofd') {
            iconName = 'rainbow';
        } else if( category == 'Eye-opener') {
            iconName = 'eye';
        } else if( category == 'Eerlijk = eerlijk') {
            iconName = 'angel';
        } else if( category == 'Aan de slag') {
            iconName = 'book';
        }

        var icon = '<svg class="Icon Icon--'+ iconName +'"><use xlink:href="#Icon--'+ iconName +'"></use></svg>'

        var part = ''+startChapter+':'+startVerse;
        if(startChapter !== endChapter) {
            part += '-'+endChapter+':'+endVerse;
        } else if(startVerse !== endVerse) {
            part += '-'+endVerse;
        }

        return (
            <div className='addon' id={ this.props.id } data-verse-id={ this.props.verseId }>
                <icon className='addonIcon' dangerouslySetInnerHTML={{__html: icon}}  />
                <h4 className='addonTitle'>{{ title }}</h4>
                <h5 className='addonPart'>{{ part }}</h5>
                <p className='addonText' dangerouslySetInnerHTML={{__html: text}}  />
            </div>
        );
    }
});

