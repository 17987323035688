var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Bijbel = function (parent, jQuery) {
	"use strict";

	// Declare a submodule
	var Fancybox = parent.Fancybox = parent.Fancybox || {};
	var jQueryInstance = jQuery;

	Fancybox.bind = function() {
		if(!Fancybox.canOpen()) {
			return;
		}

		jQueryInstance("body.chapter .bible .xrefs a").click(function(e) {Fancybox.open(this, e)});
		jQueryInstance("body.chapter aside a").click(function(e) {Fancybox.open(this, e)});
	};

	Fancybox.open = function(elm, e) {
		e.preventDefault();

		var href = jQueryInstance(elm).attr("href").split("#");
		var url = href[0] + "?" + jQueryInstance.param({"lightbox": true});

		if (href.length > 1) {
			url += "#" + href[1];
		}

		jQueryInstance.fancybox({
			"href"			: url,
			"type"			: "iframe",
			"padding"		: [50,25,25,25],
			"margin"		: 0,
			"autoWidth"		: false,
			"height"		: "100%",
			"leftRatio"		: 0,
			"topRatio"		: 0,
			"openSpeed"		: "slow",
			"closeSpeed"	: "slow",
			"openMethod"	: "slideIn",
			"closeMethod"	: "slideOut"
		});
	};

	Fancybox.canOpen = function() {
		if(jQueryInstance.fancybox.isOpen) {
			return false
		}

		if ( self !== top ) {
			return false
		}

		if (jQueryInstance(".fancybox-opened").length > 0) {
			return false;
		}

		return !(jQueryInstance(".fancybox-opened",  window.parent.document).length > 0);
	};

	// Custom Fancybox animations
	(function ($, F) {
		
		var width = $(window).width();
		var outOfSight = width <= 865 ? width : 865; // 865 is the default width of a Fancybox

		// Fly from left side to the center
		F.transitions.slideIn = function() {
			var endPos = F._getPosition(true);

			endPos.left = (parseInt(endPos.left, 10) - outOfSight) + 'px';
			endPos.opacity = 1;
			
			F.wrap.css(endPos).show().animate({
				left: '+=' + outOfSight + 'px',
				opacity: 1
			}, {
				duration: F.current.openSpeed,
				complete: F._afterZoomIn
			});
		};
		
		// Fly from center to the left
		F.transitions.slideOut = function() {
			F.wrap.removeClass('fancybox-opened').animate({
				left: '-=' + outOfSight + 'px',
				opacity: 1
			}, {
				duration: F.current.closeSpeed,
				complete: F._afterZoomOut
			});
		};

	}(jQuery, jQuery.fancybox));

	// always return the parent module
	return parent;
}(EvangelischeOmroep.Bijbel || {}, window.jQuery);