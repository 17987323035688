var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Util = (function (parent, jQuery) {
	"use strict";
	// declare a submodule BrowserDetection
	var BrowserDetection = parent.BrowserDetection = parent.BrowserDetection || {};

	function initialize() {
		if (EvangelischeOmroep.Util.BrowserDetection.isIE ()) {

			// Vervang object-fit elementen
			replaceObjectfits();
		}
		// Geen IE versie
	}

	/*
	 * Functie beschikbaar maken om de IE Versie te detecteren
	 */
	BrowserDetection.isIE = function() {
		if (navigator.userAgent.indexOf('MSIE') !== -1
        || navigator.appVersion.indexOf('Trident/') > -1){
			return true;
		}
		return false;
	}

	function replaceObjectfits() {

		var objectFits = document.getElementsByClassName("js-polyfill-objectfit");

		for(var i = 0; i < objectFits.length; i++) {

			var img = objectFits[i].getElementsByTagName('img')[0],
			    imgPath = img.getAttribute('src');

			if (imgPath) { 
				img.style.opacity = 0;
				objectFits[i].style.backgroundImage = 'url("' + imgPath + '")';
				objectFits[i].style.backgroundPosition = 'center center';
				objectFits[i].style.backgroundSize = 'cover';
			}
		}
	}

	// register handlers
	jQuery(document).ready(function() { initialize();   });

	// always return the parent module
	return parent;
}(EvangelischeOmroep.BrowserDetection || {}, jQuery));