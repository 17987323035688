let scroll = document.getElementsByClassName('scroll')
for (let i = 0; i < scroll.length; i++) {
  React.render(
    <Scroll verses={window.verses} chapter={window.chapter} ref={(bibleScroll) => {window.bibleScroll = bibleScroll}}/>,
    scroll[i],
  )
}

let scrollSidebar = document.getElementsByClassName('scrollSidebar')
for (let ii = 0; ii < scrollSidebar.length; ii++) {
  React.render(
    <Sidebar/>,
    scrollSidebar[ii],
  )
}

