var VerseEmbed = React.createClass({
	handleFocus: function (e) {
		var target = e.target;
		setTimeout(function () {
			target.select();
		}, 0);
	},
	render: function () {
		var verse = window.selectedVerse || null;
		var embedScript = '';
		if (verse !== null && typeof verse !== "undefined") {
			var url = window.location.protocol + '//' + window.location.host + '/embed/verse/' + verse._id + ".js";
			embedScript = '<script src="' + url + '" charset="utf-8"></script>'
		}

		return (
			<div className={verse ? 'addthis-embedWrapper' : 'addthis-embedWrapper disabled'}>
				<p className={'addthis-embedText'}> of embedden:</p>
				<input
					className={'addthis-embedInput'}
					type="text"
					readOnly="true"
					value={embedScript}
					onFocus={this.handleFocus}
				/>
			</div>
		);
	}
});