var NbgWall = React.createClass({

  getInitialState: function () {
    let state = {bijbelHref: 'https://www.debijbel.nl/'}
    return state
  },

  componentDidMount: function () {
    this.state.bijbelHref = this.setBijbelHref(this.props.book, this.props.chapter, this.props.verse)
  },

  setBijbelHref: function (book, chapter, verse) {
    fetch(window.location.origin + '/api/mappings/' + book).then(response => response.json())
      .then(mapping => {
        let href = 'https://www.debijbel.nl/bijbel/NBV/' + mapping['indexName'] + '-' + chapter + '#' + mapping['abbreviation'] + chapter + '.' + verse
        this.setState({ bijbelHref: href });
      });
  },

  stayOnPage: function () {
    this.props.hideWall()

    // Register event
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'trackEvent',
        trackEventCategory: 'nbgwall',
        trackEventAction: 'click',
        trackEventLabel: 'verder lezen'
      });
    }
  },

  goToDeBijbel: function () {
    // Register event
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'trackEvent',
        trackEventCategory: 'nbgwall',
        trackEventAction: 'click',
        trackEventLabel: 'debijbel.nl'
      });
    }
  },

  render: function () {
    var title = 'Verder lezen?'
    var body = 'Dat kan. Maar als je dat voor de verandering een keer wilt in een andere vertaling, dan verwijzen we je graag door naar debijbel.nl.'
    var buttonStay = 'Verder lezen'
    var buttonGo = 'Ga naar debijbel.nl'

    return (
      <div className='nbgWall'>
        <h4 className='h1 nbgWall__title'>{{title}}</h4>
        <p className='nbgWall__body'>{{body}}</p>
        <a href={this.state.bijbelHref}
           target='_new'
           onclick={this.goToDeBijbel}>
          <button className='nbgWall__button button u-marginRs u-marginBs'>{{buttonGo}}</button>
        </a>
        <button className='nbgWall__button button button--outline'
                onClick={this.stayOnPage}>{{buttonStay}}</button>
      </div>
    )
  },
})

