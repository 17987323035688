var VerseSelector = React.createClass({
    getInitialState: function () {
        if (!window.location.hash) {
            return {selectValue: 'Pagina'};
        }

        var value = window.location.hash.substring(1);
        this.changeSelectedVerse(value);
        return {selectValue: value};
    },
    changeSelectedVerse: function (value) {
        window.selectedVerse = null;

        var url = window.location.href;
        if (window.location.hash) {
            url = window.location.href.substr(0, window.location.href.indexOf('#'));
        }

        if (value == "pagina") {
            return this.setAddThisContext(document.title, url)
        }

        var verse = this.findVerse(value);
        window.selectedVerse = verse;

        var ref = verse._source.book_title + ' ' + verse._source.chapter + ':' + verse._source.number + ' -';
        return this.setAddThisContext(ref + verse._source.flat_content, (url + '#' + value));
    },
    handleChange: function (e) {
        this.setState({selectValue: e.target.value});
        this.changeSelectedVerse(e.target.value);
        document.verseEmbedInstance.forceUpdate();
    },
    setAddThisContext: function (title, url) {
        addthis.update('share', 'url', url);
        addthis.update('share', 'title', title);
        addthis.update('share', 'description', "Gevonden in de bijbel op bijbel.eo.nl.");
    },
    findVerse: function (verseId) {
        if (window.verses === null || typeof window.verses === "undefined") {
            return;
        }

        for (var i = 0; i < window.verses.length; i++) {
            if (window.verses[i]._id == verseId) {
                return window.verses[i];
            }
        }
    },
    render: function () {
        var dropDownArrow = '<svg class="Icon Icon--arrow-down"><use xlink:href="#Icon--arrow-down"></use></svg>';
        var iconShare = ' <svg class="Icon Icon--share"><use xlink:href="#Icon--share"></use></svg>';
        var options = ['<option value="pagina">Pagina&nbsp;</option>'];
        if (window.verses !== null && typeof window.verses !== "undefined") {
            for (var i = 0; i < window.verses.length; i++) {
                options.push(
                    '<option value="' + window.verses[i]._id + '">' +
                    'Vers ' + window.verses[i]._source.number +
                    '</option>'
                );
            }
        }

        var disabled = options.length > 1 ? false : true;
        return (
            <div className={'addthis-verseWrapper'}>
                <div className={'addthis-icon'} id="shareIcon" dangerouslySetInnerHTML={{__html:iconShare}}></div>
                <select
                    className={'addthis-verseSelect'}
                    id="verseSelector"
                    value={this.state.selectValue}
                    onChange={this.handleChange}
                    dangerouslySetInnerHTML={{__html: options.join('')}}
                    disabled={disabled}
                >
                </select>
                <div
                    className={disabled ? 'addthis-verseArrow disabled' : 'addthis-verseArrow'}
                    dangerouslySetInnerHTML={{__html:dropDownArrow}}
                >
                </div>
            </div>
        );
    }
});
