(function () {

    const debug = false,
          cookiePrefix = 'nbgWall_',
          chapterTriggers = [2, 5],
          maxPerDay = 2,
          maxDaysPerWeek = 4

    let chaptersToday = 0,
        viewsToday = 0,
        viewDates = null

    /**
     * Validate if a wall has to be shown
     */
    window.validateNbgWall = function () {
      log('Checking criteria...')

      // Fetch and initiate variables from cookies
      if (!hasNbgCookie('chaptersToday')) {
        setNbgCookie('chaptersToday', '0', 1)
      } else {
        chaptersToday = parseInt(getNbgCookie('chaptersToday'))
      }
      if (!hasNbgCookie('viewsToday')) {
        setNbgCookie('viewsToday', '0', 1)
      } else {
        viewsToday = parseInt(getNbgCookie('viewsToday'))
      }
      if (!hasNbgCookie('viewDates')) {
        setNbgCookie('viewDates', '', 7)
      } else {
        viewDates = getNbgCookie('viewDates')
        if (viewDates.length > 0) {
          viewDates = JSON.parse(getNbgCookie('viewDates'))
        } else {
          viewDates = []
        }
      }

      // Count this chapter view (if page not refreshed)
      if (document.referrer !== window.location.href) {
        chaptersToday++
      }
      setNbgCookie('chaptersToday', chaptersToday, 1)

      // Abort if chapter-count is not a trigger
      if (chapterTriggers.indexOf(chaptersToday) < 0) {
        log('Aborted. This chapter count (' + chaptersToday + ') is not a trigger.')
        hideNbgWall()
        return
      }

      // Abort if max views today has been reached
      if (getNbgCookie('viewsToday') >= maxPerDay) {
        log('Aborted. Maximum views today (' + maxPerDay + ') has been reached.')
        hideNbgWall()
        return
      }

      // Abort if max days in past 7 days has been reached
      if (viewDatesThisWeek() >= maxDaysPerWeek) {
        log('Aborted. Maximum amount of (' + maxDaysPerWeek + ') days in past 7 days has been reached.')
        hideNbgWall()
        return
      }

      showNbgWall()

    }

    /**
     * Returns the amount of views in past 7 days
     * @returns {number}
     */
    function viewDatesThisWeek () {
      let today = new Date(),
          counterThisWeek = 0

      // Loop through the dates on which a wall was shown
      for (let i = 0; i < viewDates.length; i++) {
        if (viewDates[i] !== '') {

          let viewDate = new Date(viewDates[i])

          if (numDaysBetween(today, viewDate) > 7) {
            viewDates.splice(i, 1)

            log('An old view date (' + viewDate + ') is removed from memory.')
          } else {
            counterThisWeek++
          }

        }
      }

      // Update cookie with recent dates
      setNbgCookie('viewDates', JSON.stringify(viewDates), 7)

      return counterThisWeek
    }

    /**
     * Shows the NBG wall on the page
     */
    function showNbgWall () {

      window.bibleScroll.addNbgWall()

      countNbgWall()

      if (chaptersToday != null && viewsToday != null && viewDates != null) {
        log('A wall has been added to the page. '
          + '(chapter count: '
          + chaptersToday
          + ', views today: '
          + viewsToday
          + ', days this week: '
          + viewDates.length
          + ')')
      }
    }

    /**
     * Remembers showing the NBG wall
     */
    function countNbgWall () {
      viewsToday++
      setNbgCookie('viewsToday', viewsToday, 1)

      // Only count this day once
      if (viewsToday === 1) {
        viewDates.push(new Date())
        setNbgCookie('viewDates', JSON.stringify(viewDates), 7)
      }
    }

    /**
     * Remove the NBG wall from the page
     */
    function hideNbgWall () {
      window.bibleScroll.removeNbgWall()
    }

    /**
     * Sets a cookie for the NBG wall
     * @param name
     * @param value
     * @param days
     */
    function setNbgCookie (name, value, days) {
      let expires = ''
      if (days) {
        let date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
      }
      document.cookie = cookiePrefix + name + '=' + (value || '') + expires + '; path=/'
    }

    /**
     * Gets the value of the NBG wall cookie
     * @param name
     * @returns {*}
     */
    function getNbgCookie (name) {
      name = cookiePrefix + name + '='
      let cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i]

        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1, cookie.length)
        }

        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length)
        }
      }
      return null
    }

    /**
     * Checks if a NBG wall cookie exists
     * @param name
     * @returns {boolean}
     */
    function hasNbgCookie (name) {
      name = cookiePrefix + name + '='
      return !!document.cookie.split(';').filter((item) => item.includes(name)).length
    }

    function numDaysBetween (d1, d2) {
      let diff = Math.abs(d1.getTime() - d2.getTime())
      return diff / (1000 * 60 * 60 * 24)
    }

    function log (log) {
      if (debug)
        console.log('NBG wall: ' + log)
    }

    // If this page is a chapter of the bible
    if (navigator.cookieEnabled && document.body.classList.contains('chapter')) {
      window.validateNbgWall()
    }

  }

)(document, window)
