var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Bijbel = function (parent,jQuery) {
  "use strict";

  var KEYCODE_ENTER = 13;

  // register handlers
  jQuery(document).ready(function() {
    // Link the Xrefs and footnotes
    EvangelischeOmroep.Bijbel.Util.linkXrefsAndFootnotes();

    // Copyprotector
    EvangelischeOmroep.Bijbel.Util.jctextcopyprotector();

    // Search Suggestions: Books
    var bookSuggestions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {
        'url': '/api/bookssuggestions'
      },
    });

    // Search Suggestions: Articles
    var articleSuggestions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      'remote': {
        'url': '/api/articlessuggestions/%QUERY',
        'wildcard': '%QUERY',
        'cache': false
      },
    });

    // Search Suggestions: Search
    var searchSuggestions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      //'prefetch': '/api/searchsuggestions/',
      'remote': {
        'url': '/api/searchsuggestions/%QUERY',
        'wildcard': '%QUERY',
        'cache': false
      },
    });

    // Set-up the Fancybox for verse refferals
    EvangelischeOmroep.Bijbel.Fancybox.bind();

    //Get real width of page scroll-bar
    var w1 = $(window).width();
    $("html").addClass('fancybox-lock-test');
    var w2 = $(window).width();
    $("html").removeClass('fancybox-lock-test');
    $("<style type='text/css'>.fancybox-margin{padding-right:" + (w2 - w1) + "px;}</style>").appendTo("head");


    // Set add-this chapter selection if available
    if(typeof addthis != "undefined" && addthis != null) {
      addthis.addEventListener('addthis.ready', function () {
        var verseSelectorContainer = document.getElementById("verseSelectorContainer");
        var verseEmbedContainer = document.getElementById("verseEmbedContainer");
        if(verseSelectorContainer == null || verseEmbedContainer == null) {
          return;
        }

        document.verseSelectorInstance = React.render(<VerseSelector />, verseSelectorContainer);
        document.verseEmbedInstance = React.render(<VerseEmbed />, verseEmbedContainer);
      });
    }

    jQuery('#searchField').typeahead({
        hint: true,
        highlight: true,
        minLength: 2
      },
      {
       name: 'search-suggestions',
       display: 'value',
       source: searchSuggestions,
       limit: 9,
       templates: {
         header: '<h3 class="zoeken">Zoeken op:</h3>'
       }
      },
      {
        name: 'book-suggestions',
        display: 'value',
        source: bookSuggestions,
        templates: {
          header: '<h3 class="boeken">Boeken:</h3>'
       }
      },
      {
        name: 'article-suggestions',
        display: 'value',
        source: articleSuggestions,
        limit: 9,
        templates: {
          header: '<h3 class="artikelen">Artikelen:</h3>'
       }
      });
    });

    var searchField = jQuery('#searchField');

    searchField.on('typeahead:select', function(ev, suggestion) {
      ev.preventDefault();

      EvangelischeOmroep.Bijbel.Util.redirect(suggestion.url);
    });

    // disable form submit on ENTER by default
    searchField.on('keypress keyup', function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === KEYCODE_ENTER) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
      }
    });

    // enable form submit on ENTER when no suggestion was selected
    searchField.on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === KEYCODE_ENTER && !jQuery('.tt-menu .tt-selectable.tt-cursor').length) {
        searchField.parents('form').submit();
      }
    });

    // Only submit searchform if input not empty
    document.getElementById("searchForm").addEventListener('submit', function(e) {
      if (!searchField.val() || searchField.val() === '') {
        e.preventDefault();

        // Minimize searchfield
        var checkbox = document.getElementById("campaignSearch__checkbox");
        if (checkbox) {
          checkbox.checked = false;
        }
      }
    });

    // Voor debugging : Sessie verwijderen
    //addToHomescreen.removeSession();

    // Add to homescreen functionality for android and iOS users without
    // native App Install Banners.
    // See: https://developers.google.com/web/fundamentals/engage-and-retain/app-install-banners/
    if (!('serviceWorker' in navigator)) {
      addToHomescreen({
        skipFirstVisit: true,
        maxDisplayCount: 1,
        //debug: true,
      });
    }

    /*
     * For ios we can detect standalone mode by using 'window.navigator.standalone'
     * Android, however, doesn't have this property. Therefore we need to check the display mode
     *    (https://developers.google.com/web/updates/2015/10/display-mode)
     *
     * If Service Workers are supported, the manifest.json will have a startUrl property,
     * opening automatically at the right URL.
     */
    if (!('serviceWorker' in navigator) && (window.navigator.standalone == true || (window.matchMedia('(display-mode: standalone)').matches)) ) {
      if (!jQuery.cookie("firstVisitFromWebapp")){

        // set cookie now
        jQuery.cookie("firstVisitFromWebapp", "true");

        // do your stuff
        switch (location.hostname) {
          case 'bijbel.eo.nl':
            EvangelischeOmroep.Bijbel.Util.redirect('https://bijbel.eo.nl/');
            break;
          case 'acceptatie-bijbel.eo.nl':
            EvangelischeOmroep.Bijbel.Util.redirect('https://acceptatie-bijbel.eo.nl/');
            break;
          default:
            EvangelischeOmroep.Bijbel.Util.redirect('https://bijbel.eo.nl/');
        }

      } else {
        // alert ("got a cookie");
      }
    }

  // always return the parent module
  return parent;
}(EvangelischeOmroep.Bijbel || {},jQuery);
