/**
 * Registers the Service Worker.
 */
(function ($, document, window) {

	'use strict';

	if (!('serviceWorker' in navigator) || (!'fetch' in window)) {
		return;
	}

	// Make sure the global OneSignal variable exists.
	// See: https://documentation.onesignal.com/docs/web-push-sdk#section-loading-sdk-asynchronously
	window.OneSignal = window.OneSignal || [];

	/**
	 * Loads a JS file by appending an async script tag to the head.
	 *
	 * @param {String} url
	 */
	function loadScript(url) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;

		var firstScript = document.querySelector('script');
		firstScript.parentNode.insertBefore(script, firstScript);
	}

	/**
	 * Loads the OneSignalSDK
	 */
	function loadOneSignalSDK() {
		loadScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js');
	}

	/**
	 * Logs an Event to Google Analytics, via Google Tag Manager.
	 *
	 * @param {String} action
	 */
	function logEvent(action) {
		if (window.dataLayer && action) {
			window.dataLayer.push({
				event: 'trackEvent',
				trackEventCategory: 'Notifications Permission',
				trackEventAction: action
			});
		}
	}

	/**
	 * Registers the Service Worker. Ideally, the OneSignal Web Push SDK
	 * would register it for us, but it will only do so when the user has
	 * allowed push notifications. Therefore, we have to register it by hand.
	 *
	 * See: https://github.com/OneSignal/OneSignal-Website-SDK/issues/231
	 *
	 * @return {Promise}
	 */
	function registerServiceWorker() {
		return navigator.serviceWorker.register('/OneSignalSDKWorker.js');
	}

	/**
	 * Sets up the Service Worker by loading the OneSignal SDK, which will
	 * in turn load the local OneSignal Service Worker and our own
	 * generated Service Worker. See: public/OneSignalSDKWorker.js and
	 * public/OneSignalSDKUpdaterWorker.js
	 */
	function setupServiceWorker() {
		loadOneSignalSDK();

		window.OneSignal.push(function() {
			window.OneSignal.setDefaultTitle('Bijbel Online');

			window.OneSignal.init({
				appId: '36630c7a-8d6a-45db-ab11-21938f7c44b5',
				allowLocalhostAsSecureOrigin: true,
				autoRegister: false,
				welcomeNotification: {
					title: 'Bijbel Online',
					message: 'Dank voor je aanmelding! Vanaf nu ontvang je elke dag een inspirerende bijbeltekst (als pushbericht).'
				},
				promptOptions: {
					actionMessage: 'Wil je voortaan dagelijks een inspirerende bijbeltekst ontvangen (als pushbericht)?',
					acceptButtonText: 'Ja, graag!',
					cancelButtonText: 'Nee, dank je.'
				}
			});

			window.OneSignal.on('popoverShown', logEvent.bind(null, 'request'));
			window.OneSignal.on('popoverAllowClick', logEvent.bind(null, 'allow'));
			window.OneSignal.on('popoverCancelClick', logEvent.bind(null, 'deny'));
		});
	}

	/**
	 * Shows the OneSignal Permission Message slidedown
	 */
	function showPermissionMessage() {
		window.OneSignal.push(function() {
			window.OneSignal.showHttpPrompt().catch(function(e) {
				if (e.name === 'PermissionMessageDismissedError') {
					return;
				}

				throw e;
			});
		});
	}

	/**
	 * Retrieves the current navigation count from a cookie.
	 *
	 * @returns {Number}
	 */
	function getNavigationCount() {
		var count = $.cookie('navigationCount', Number);

		return count === undefined ? 0 : count;
	}

	/**
	 * Sets the current navigation count in a cookie.
	 *
	 * @param {Number} count
	 */
	function setNavigationCount(count) {
		$.cookie('navigationCount', count, {
			path: '/'
		});
	}

	/**
	 * Increases the navigation count.
	 */
	function increaseNavigationCount() {
		setNavigationCount(getNavigationCount() + 1);
	}

	/**
	 * Figures out if the conditions to show the permission message are met.
	 *
	 * @returns {Boolean}
	 */
	function matchesPermissionMessageConditions() {
		return getNavigationCount() >= 2;
	}

	document.addEventListener('DOMContentLoaded', function() {
		registerServiceWorker();
		setupServiceWorker();

		if (matchesPermissionMessageConditions()) {
			showPermissionMessage();
		// Don't count page reloads and cookiewall redirects as navigation actions
		} else if (document.referrer !== window.location.href) {
			increaseNavigationCount();
		}

		// Set up a listener for client-side navigation actions, like browsing chapters
		document.addEventListener('bijbel-nav-action', function() {
			if (matchesPermissionMessageConditions()) {
				showPermissionMessage();
			} else {
				increaseNavigationCount();
			}
		});
	});

})(window.jQuery, document, window);
