var Verse = React.createClass({
    render: function() {
        var content = this.props.children;
        var text = this.props.children.text;
        var chapter = this.props.chapter;
        var number = this.props.number;
        var className = '';
        if(window.location && this.props.id == window.location.hash.substr(1)) {
            className = 'highlighted';
        }

        return (
            <span dangerouslySetInnerHTML={{__html: text}} className={className} />
        );
    }
});

