var EvangelischeOmroep = EvangelischeOmroep || {};
EvangelischeOmroep.Bijbel = EvangelischeOmroep.Bijbel || {};

var Sidebar = React.createClass({
    intervalId: undefined,
    getInitialState: function() {
        return {data: [], currentChapter: window.chapter};
    },
    componentDidMount: function() {
        this.addVerses(window.verses);
        // Because the heigth is different when the fonts are loaded, recalculate the height every second
        this.intervalId = window.setInterval(this.updateOffsets,1000);
        EvangelischeOmroep.Bijbel.newVersesListener = this.addVerses;
        EvangelischeOmroep.Bijbel.updateChapterListener = this.changeChapter;
    },
    componentDidUnMount: function() {
        clearInterval(this.intervalId);
        EvangelischeOmroep.Bijbel.newVersesListener = undefined;
        EvangelischeOmroep.Bijbel.updateChapterListener = undefined;
    },
    addVerses: function(verses) {
        var data = this.state.data;
        verses.forEach(function(verse) {
            if(verse._source.hasOwnProperty('addons')){
                Array.prototype.push.apply(data, verse._source.addons);
            }
        });
        data.sort(function(addonA, addonB) {
            var idA = addonA.verse_id.split('-');
            var valueA = parseInt(idA[1])*1000+parseInt(idA[2]);
            var idB = addonB.verse_id.split('-');
            var valueB = parseInt(idB[1])*1000+parseInt(idB[2]);
            return valueA - valueB;
        });
        this.setState({data: data});
    },
    changeChapter: function(chapter) {
        this.setState({currentChapter:chapter});
    },
    componentDidUpdate: function() {
        this.updateOffsets();
    },
    updateOffsets: function() {
        var offset = $('.sidebar').offset().top;
        var addonCount = document.getElementsByClassName('addon').length
        for (var addonCtr = 0; addonCtr < addonCount; addonCtr++) {
            var $addon = $('#js-addon-'+addonCtr);
            var $verse = $('#'+$addon.data('verseId'));
            var addonOffset = offset;
            if($verse && $verse.offset()) {
                addonOffset = $verse.offset().top;
            }
            if(addonOffset > offset) {
                var margin = Math.floor(addonOffset - offset) - 3;
                $addon.css('margin-top',margin+'px');
            }
            offset = $addon.offset().top + $addon.outerHeight();
        }
    },
    render: function() {
        var addonNodes = [];
        var addonCtr = 0;
        var currentChapter = this.state.currentChapter;
        this.state.data.forEach(function(addon) {
            if(addon.start_chapter == currentChapter) {
                var id = 'js-addon-'+addonCtr;
                addonNodes.push(
                    <Addon key={addon.addon_id} id={id} verseId={addon.verse_id} addon={addon}></Addon>
                );
                addonCtr++;
            }
        });
        return (
            <div className='addons'>
                {addonNodes}
            </div>
        );
    }
});
