var  openx = function (selector, options = {}) {
  var self = this || {}

  if (!selector) {
    console.error('Openx kan niet geladen worden zonder selector')
    return false
  }

  self.elements = document.querySelectorAll(selector)

  self.options = {
    addblock: options.addblock ? {
      innerHTML: options.addblock.innerHTML || ''
    } : false,
    article: options.article ? {
      minParagraphs: options.article.minParagraphs || 1,
      container: options.article.container || ''
    } : false,
    toggleDisplay: options.toggleDisplay || false
  }

  window.isOpenxLoaded = window.isOpenxLoaded !== 'undefined' ? window.isOpenxLoaded : false

  function init () {

    // Check of element bestaat
    if (self.elements.length <= 0) {
      return false
    }

    // Check of cookiewall al geladen is. Wanneer niet wacht totdat deze klaar is en begin opnieuw.
    if (!window.isOpenxLoaded && !isCookiewallLoaded()) {
      window.addEventListener('CCM_done', function (e) {
        init()
      }, false)

      return false
    }

    // Check of er consent is voor de recommendations
    if (!hasRecommendationsConsent()) {
      return false
    }

    // Banner is geladen in een artikel. Check of aan alle condities voldaan is
    if (self.options.article) {
      let container = document.querySelector(self.options.article.container)

      if (!container) {
        console.warn('openx: article container bestaat niet op deze pagina')
      } else {
        let paragraphs = container.querySelectorAll('p')
        if (!container) { console.warn('openx: aangegeven artikel container zit niet in de dom') }

        // Bij minder dan de aangegeven paragraven stop met uitvoeren en verberg banners
        if (paragraphs.length < self.options.article.minParagraphs) {
          toggleDisplay('none')
          return false
        }
      }

    }

    // Check of openx al eerder ingeladen is zo niet laad deze en herstart init
    if (!window.isOpenxLoaded && !window.hasAddBlock) {
      loadOpenx(init)
      return false
    }

    // Adblock
    if (window.hasAddBlock) {
      if (self.options.addblock) {
        showAddblockBanner()
      } else if (self.options.toggleDisplay) {
        toggleDisplay('none')
      }
      return true
    }

    // refresh revive
    self.revive = getRevive()
    self.revive.refresh()

    // Toon wanneer aangegeven
    if (self.options.toggleDisplay) {
      toggleDisplay('block')
    }
  }

  function toggleDisplay (display) {
    self.elements.forEach(function (el) {
      el.style.display = display
    })
  }

  function isCookiewallLoaded () {
    return (!(typeof window.ccm !== 'object') && typeof window.ccm === 'object')
  }

  function hasRecommendationsConsent () {
    return (isCookiewallLoaded() && window.ccm.getCategoryConsent('recommendations') === true)
  }

  function showAddblockBanner () {
    self.elements.forEach(function (el) {
      let ad = el.querySelector('ins')

      // unblock ins
      ad.style.cssText = 'display:flex !important; text-decoration: none; height:100%;'

      // vol
      ad.innerHTML = self.options.addblock.innerHTML
    })

    if (self.options.toggleDisplay) {
      toggleDisplay('block')
    }
  }

  function loadOpenx (cb) {
    const source = 'https://delivery.ads.eo.nl/www/delivery/asyncjs.php'
    var script = document.createElement('script')
    var prior = document.getElementsByTagName('script')[0]

    script.async = 1

    script.onload = script.onreadystatechange = function (_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null
        script = undefined

        if (!isAbort) {
          window.isOpenxLoaded = true

          if (cb) { cb() }
        } else {
          console.error('openx kan niet geladen worden er is iets misgegaan bij het ophalen van het script')
        }
      }
    }

    script.onerror = function (e) {
      // Hoogstwaarschijnlijk heeft de gebruiker add block aanstaan
      window.hasAddBlock = true
      if (cb) { cb() }
    }

    script.src = source
    prior.parentNode.insertBefore(script, prior)
  }

  function getRevive () {
    let revivekey = Object.keys(window.reviveAsync)[0]

    if (!revivekey) {
      return false
    }

    return window.reviveAsync[revivekey]
  }

  if (document.readyState === 'complete' ||
    document.readyState === 'loaded' ||
    document.readyState === 'interactive') {
    init()
  } else {
    document.addEventListener('DOMContentLoaded', init)
  }
}

var banner = openx('.eo-Banner');
