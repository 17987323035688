var LoadingButton = React.createClass({
	getInitialState: function() {
		return {loading:false};
	},
	handleClick: function() {
		var that = this;
		this.setState({loading:true});
		this.props.onStartLoading(this.props.chapter, this.ready);
	},
	ready: function() {
		this.setState({loading:false});
	},
	render: function() {
		var chapter = this.props.chapter;
		var text = this.props.children;
		var classes = {
			base: 'button ' + (this.props.extraClass || ''),
			disabled: ' is-disabled',
			loading: ' is-loading'
		};

		if(this.state.loading) {
			return (
				<button className={ classes.base + classes.loading }><span className="is-loading-label">Laden...</span></button>
			);
		} else if(chapter > 0 && chapter <= this.props.max) {

			return (
				<button onClick={this.handleClick} className={ classes.base }>{text}</button>
			);
			
		} else {
			return (
				<button className={ classes.base + classes.disabled } disabled></button>
			);
		}
	}
});

