var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Bijbel = function (parent) {
    "use strict";

    // Declare a submodule
    var Util = parent.Util = parent.Util || {};

    /*
     * toggleHandler Function
     */
    Util.toggleHandler = function(clickElement,toggleElement) {
        clickElement.addEventListener("click", function (e) {
            e.preventDefault();
            // First, close other active elements
            jQuery('.is-active:not(#'+ toggleElement.id +')').removeClass('is-active');
            (toggleElement.classList.contains("is-active") === true) ? toggleElement.classList.remove("is-active") : toggleElement.classList.add("is-active");
        });
    }

    /*
     * redirect Function
     */
    Util.redirect = function(uri) {
        window.location.href = uri;
    }

    /*
     * Helperfuntion to re(link) the xrefs and footnote(s)
     */
    Util.linkXrefsAndFootnotes = function () {
        // Init togglers for menu and sharebutton in Mainmenu
        if (jQuery('div').hasClass('scroller')) {
            // itereer over de xref en footnotes en maak ze klikbaar
            jQuery( ".js-xref,.js-footnote" ).each(function( index ) {
                var id = jQuery(this).data('id');
                // De onclick events eerst verwijderen
                jQuery(this).get(0).removeAttribute("onclick");

                // Click handler toevoegen voor xrefs en footnote's
                EvangelischeOmroep.Bijbel.Util.toggleHandler(jQuery(this).get(0), document.getElementById(id));
                // Click handler toevoegen voor de  close knop .
                if (document.getElementById('js-close-'+id) != null) {
                    document.getElementById('js-close-' + id).removeAttribute("onclick");
                    EvangelischeOmroep.Bijbel.Util.toggleHandler(document.getElementById('js-close-'+id), document.getElementById(id));
                }
            });
        }
        return true;
    }

    /*
     * Borrowed from https://github.com/jaschahal/jctextcopyprotector
     */
    Util.jctextcopyprotector = function (settings) {
        settings = jQuery.extend({
            blockRightClick:true,
            blockDocTextSelection:true,
            useCSS:true,
            blockPageSave:true,
            alertUser:false,
            alertMessage:'Helaas, we hebben liever niet dat je delen van de bijbel kopieert.',
            callback:function(){}
        },settings);
        if(settings.blockRightClick) {
            jQuery(document).contextmenu(function(evt){
                if(settings.alertUser && settings.alertMessage.length > 0) {
                    alert(settings.alertMessage);
                }
                evt.preventDefault();
                return false;
            });
        }
        if(settings.blockDocTextSelection && !settings.useCSS) {
            jQuery(document)[0].onselectstart = function(evt) {
                if(settings.alertUser && settings.alertMessage.length > 0) {
                    alert(settings.alertMessage);
                }
                evt.preventDefault();
                return false;
            };
        }
        else if(settings.blockDocTextSelection && settings.useCSS) {
            jQuery('html,body').css({
                '-moz-user-select':'none',
                '-webkit-user-select':'none',
                'user-select':'none',
                '-ms-user-select':'none'
            });
        }
        if(settings.blockPageSave) {
            jQuery(document).keydown(function(e) {
                if (e.ctrlKey && (e.which == 83 || e.which == 115 || e.which == 97 || e.which == 65 || e.which == 67 || e.which == 99)) { // blocks CTRL c (copy)+a(select all)+s(save)
                    if(settings.alertUser && settings.alertMessage.length > 0) {
                        alert(settings.alertMessage);
                    }
                    e.preventDefault();
                    return false;
                }
            });
        }
    }

    // always return the parent module
    return parent;
}(EvangelischeOmroep.Bijbel || {});
