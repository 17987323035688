var EvangelischeOmroep = EvangelischeOmroep || {};

EvangelischeOmroep.Bijbel = function (parent) {
  "use strict";

  /*
   Initialize the selectBox for selecting books on homepage
   Bij selecteren van bijbelboek op Homepage meteen naar hoofdstuk 1 van dat bijbelboek springen
   */
  function initSelectBoxBooksHome() {
    var selectBoxBooksHome = document.getElementById("selectBoxBooksHome");
    if (selectBoxBooksHome) {
      selectBoxBooksHome.addEventListener("change", function () {
        if (this.value != "")
          EvangelischeOmroep.Bijbel.Util.redirect('/bijbel/' + this.value + /1/);
      });
    }
  }

  function initSelectBoxBooksHeader(e) {
    var formerBook = 'undefined';
    // Vorige book "bewaren" voordat het verandert met het Focus event
    e.addEventListener("focus", function () {
      formerBook = this.value;
    });
    e.addEventListener("change", function () {
      var selectedBook = e.options[e.selectedIndex].value;
      if (selectedBook == formerBook || formerBook == 'undefined') {
        var selectBoxChaptersHeader = document.getElementById("selectBoxChaptersHeader");
        var selectedChapter = selectBoxChaptersHeader.getAttribute('data-selectedchapter')

        // allereerst: lijst met chapters leegmaken
        selectBoxChaptersHeader.options.length = 0;

        if (selectBoxChaptersHeader) {
          for (var i = 1; i <= this.options[this.selectedIndex].getAttribute('data-chapters'); i++) {
            var option = document.createElement("option");
            option.text = i;
            option.value = i;
            if (i==selectedChapter) {
              option.selected = true;
            }
            selectBoxChaptersHeader.appendChild(option);
          }
        }
      } else {
        if (this.value != "")
          EvangelischeOmroep.Bijbel.Util.redirect('/bijbel/' + this.value + '/1/');
      }
    });
    var  selectBoxBooksHeaderIcon = document.getElementById("selectBoxBooksHeaderIcon");
    selectBoxBooksHeaderIcon.addEventListener("click", function() {
      // Doe de sleeplijst openen
      doMouseEvent("mousedown", selectBoxBooksHeader);
    });
  }

  function initSelectBoxChaptersHeader(e) {
    var selectedBook = e.options[e.selectedIndex].value;
    var selectBoxChaptersHeader = document.getElementById("selectBoxChaptersHeader");
    selectBoxChaptersHeader.addEventListener("change", function () {
      EvangelischeOmroep.Bijbel.Util.redirect('/bijbel/' + selectedBook + '/' + this.value + '/');
    });
    var  selectBoxChaptersHeaderIcon = document.getElementById("selectBoxChaptersHeaderIcon");
    selectBoxChaptersHeaderIcon.addEventListener("click", function() {
      // Doe de sleeplijst openen
      doMouseEvent("mousedown", selectBoxChaptersHeader);
    });
  }

  function initSelectBoxesInHeader() {
    var selectBoxBooksHeader = document.getElementById("selectBoxBooksHeader");
    if (selectBoxBooksHeader) {
      initSelectBoxBooksHeader(selectBoxBooksHeader);
      initSelectBoxChaptersHeader(selectBoxBooksHeader);
      doEvent("change", selectBoxBooksHeader);
    }
  }

  /*
    Generic Helperfunction to fire HTMLevents on an element
   */
  function doEvent(eventtype,element) {
    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent(eventtype, false, true);
      element.dispatchEvent(evt);
    } else {
      selectBoxBooksHeader.fireEvent("on" + eventtype);
    }
  }

  /*
   Generic Helperfunction to fire MouseEvents on an element
   */
  function doMouseEvent(eventtype,element) {
    if ("createEvent" in document) {
      var evt = document.createEvent("MouseEvents");
      evt.initMouseEvent(eventtype, true, true, window);
      element.dispatchEvent(evt);
    }
  }

  /*
   Focus op searchForm.searchField (wanneer form geopend wordt)
   */
  function initFocusOnOpen() {
    var searchToggle = document.getElementsByClassName("js-search-toggle")[0];
    if (searchToggle) {
      searchToggle.addEventListener("click", function() {
        document.getElementById("searchField").focus();
      });
    }
  }

  /*
   Focus op searchForm.searchField (alleen op homepage)
   */
  function initSearch() {
    if (!document.getElementById("mainMenu")) {
      var searchField = document.getElementById("searchField");
      searchField && searchField.focus();
    }

    jQuery("#searchField").keyup(function (e) {
      if (e.keyCode == 13) {
        // Do something
        document.getElementById("searchForm").submit();
      }
    });
  }

  /*
   Geeft header class wanneer buiten viewport gescrolld
   */
  function initScroll() {
    var header = document.getElementsByClassName("js-campaign-header")[0];
    
    if (header) {
      var setClass = function() {
        if (window.scrollY > (header.offsetHeight) / 3) {
          header.classList.add("is-floating");
        } else {
          header.classList.remove("is-floating");
        }
      };

      setClass();

      document.addEventListener("scroll", function (event) {
        setClass();
      });
    }
  }

  /*
   Init togglers for menu and sharebutton in Mainmenu
   */
  function initialize() {
    if (document.getElementById("mainMenu") && document.getElementById("mainMenu")) {
      EvangelischeOmroep.Bijbel.Util.toggleHandler(document.getElementById("menuIcon"), document.getElementById("mainMenu"));
      EvangelischeOmroep.Bijbel.Util.toggleHandler(document.getElementById("shareIcon"), document.getElementById("shareAddthis"));
      EvangelischeOmroep.Bijbel.Util.toggleHandler(document.getElementById("closeButton"), document.getElementById("shareAddthis"));
      EvangelischeOmroep.Bijbel.Util.toggleHandler(document.getElementById("searchIconMobile"), document.getElementById("searchField"));
    }

    // Initialize selectbox for books on homepage
    initSelectBoxBooksHome();

    // Initialize selectboxes for books/chapters in header
    initSelectBoxesInHeader();

    // init focus searchfield on open
    initFocusOnOpen();

    // init focus searchfield on homepage
    initSearch();

    // init scroll events
    initScroll();
  }

  // register handlers
  document.addEventListener("DOMContentLoaded", function (event) {
    initialize();
  });

  // always return the parent module
  return parent;
}(EvangelischeOmroep.Bijbel || {});





