var TextBlock = React.createClass({
    render: function() {
        var verses = this.props.children;
        var blockId = this.props.blockId;
        if(verses.length === 0) {
            return (<div></div>);
        }

        var firstVerseContent = verses[0]._source.content;
        var title;

        var verseNodes = verses.map(function(verse) {
            // get the content element matching the blockId

            var content;
            verse._source.content.forEach(function (block) {
                if(block.block_id === blockId){
                    content = block;
                }
            });

            if(!title) {
                title = content.title;
            }

            return (
                <Verse key={verse._id} id={verse._id} nummer={verse._source.number} chapter={verse._source.chapter}>{content}</Verse>
            );
        });
        return (
            <div className="block">
                <div dangerouslySetInnerHTML={{__html: title}}  />
                <p>{verseNodes}</p>
            </div>
        );

    }
});

